<template>
  <v-app class="my-application">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    layout() {
      return this.$store.state.layout
    }
  },
  created() {

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.querySelector('[rel="icon"]').href = "/images/favicon-dark.png";
    } else {
      document.querySelector('[rel="icon"]').href = "/images/favicon-light.png";
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        document.querySelector('[rel="icon"]').href = event.matches ? "/images/favicon-dark.png" : "/images/favicon-light.png";
    });
  }
}
</script>

<style lang="scss">
$font-family: 'SF UI Text Regular', serif;
  .my-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      font-family: $font-family, sans-serif !important;
    }
    // font-family: $font-family, sans-serif !important;
  }
</style>
