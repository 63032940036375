<template>
    <div id="about" class="bg-primary-green container py-16 overflow-visible tw-min-h-[600px]" >
        <div :style="{backgroundImage: 'url(\'images/svg/animated-shape-2.svg\')'}">
            <v-container :class="{'mx-width': display.lgAndUp.value}" >
            <v-row>
                <v-col :offset="display.mdAndUp.value ? 5 : 0">
                    <div class="">
                        <div class="tw-flex tw-items-center">
                            <div>01.</div>
                            <div class="tw-border-b-neutral-800 tw-border-b tw-w-5 mx-1"></div>
                            <div>About me</div>
                            <div class="tw-border-b-neutral-800 tw-border-b tw-w-7 ml-1"></div>
                        </div>
                        <div class="text-primary-color text-md-h4 text-h5 tw-font-bold">
                            Who Am I
                            <!-- <span class="point text-pink">.</span> -->
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div>
                <v-row >
                    <v-col cols="12" sm="6" md="5">
                        <div>
                            <img class="w-100 tw-max-w-[500px]" src="images/pose_43.png" alt="">
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="7">
                        <div class="py-10">
                            <p class="tw-text-sm mb-5 bg-primary-color text-primary-green tw-w-fit px-2 tw-rounded-sm">Introduce</p>
                            <p class="tw-text-3xl">Hello! I'm Yassine El Aatmani</p>
                            <p class="my-5 tw-italic tw-text-lg">
                                - Programming is not about being the best, it's about being better than you were yesterday.
                            </p>
                            <p class="text-body-1 mt-10 mt-md-0">
                                I'm a full stack developer with a passion for creating digital solutions. I completed my studies in digital development at OFPPT, where I honed my skills and expanded my knowledge of the latest technologies and trends in the field.
                            </p>
                            <p class="mt-2 text-body-1">
                                I have experience in both fields frontend and backend. My goal is to craft beautiful and intuitive interfaces that solve real-world problems. I'm always seeking new challenges to further my development and make a positive impact in the digital world.
                            </p>
                            <!-- <p class="text-body-1">
                            As a full-stack developer, I am driven by a passion for creating dynamic and engaging web applications. My expertise lies in using Vue.js and React for front-end development and Laravel for back-end development. With my strong understanding of both front-end and back-end technologies, I am confident in my ability to bring value to any project.
                            </p> -->
                            <!-- <p class="md:tw-text-lg tw-text-md mt-5">
                                I am motivated by the idea of using my skills to create solutions that make a difference, and I am excited for the opportunity to collaborate with others and bring my ideas to life. I believe that my technical skills, combined with my drive to learn and grow, make me a promising candidate for any full-stack development role.
                            </p> -->
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        </div>
    </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {
    components: {  },

    data() {
        return {}
    },
    computed: {
        display() {
            return useDisplay()
        }
    }
    
}
</script>
<style scoped>
    .point {
        font-size: 40px;
        font-weight: bold;
    }

</style>