<template>
  <div id="projects" class="bg-white container py-6 pb-0 overflow-hidden bg-primary-color">
    <v-container :class="{ 'mx-width': display.lgAndUp.value }">
      <div class="tw-flex tw-justify-center">
        <div class="tw-flex tw-items-center tw-flex-col">
          <div class="tw-flex text-primary-green tw-items-center">
            <div>03.</div>
            <div class="tw-border-b-emerald-800 tw-border-b tw-w-5 mx-1"></div>
            <div>My Work</div>
            <div class="tw-border-b-emerald-800 tw-border-b tw-w-7 ml-1"></div>
          </div>
          <div class="text-white text-md-h4 text-h5 tw-font-bold">
            Work I Have Done
            <!-- <span class="point text-pink">.</span> -->
          </div>
        </div>
      </div>
      <div class="mt-10 tw-flex tw-flex-col md:tw-gap-10 tw-gap-2">
        <ProjectCard
          v-for="project in projects"
          :key="project.id"
          :project="project"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
import { useDisplay } from "vuetify/lib/framework.mjs";
import ProjectCard from "@/components/home/partials/ProjectCard";

export default {
  components: { ProjectCard },

  data() {
    return {
      projects: [
        {
          id: 1,
          development: true,
          name: "My Ebag",
          live: "https://myebag.elaatmani.com",
          github: "https://github.com/devYa9/myebag-frontend",
          logo: "images/projects/myebag-logo.svg",
          image: "images/projects/myebag.jpg",
          description: `My Ebag is a dynamic and user-friendly e-commerce website, aimed at
                                    providing customers with an immersive shopping experience. My Ebag 
                                    prioritizes <span class='text-primary-green'>user experience</span> with a sleek design, intuitive navigation, and responsive layout. The
                                    website is optimized for desktop and mobile devices, making it
                                    accessible for customers on any platform.`,
          tools: ["vue", "php", "vuetify", "mysql"],
        },
        {
          id: 4,
          development: false,
          name: "HubButton",
          live: "https://hubutton.com",
          image: "images/projects/hubutton.png",
          description: `HubButton enables seamless integration with over 10 major messaging platforms, allowing users to effortlessly add Messaging or contact form buttons to their site's source code and also benefit from direct customer communication via these buttons.`,
          tools: ["vue", "tailwind", "vuetify", "laravel"],
        },
        {
          id: 2,
          development: true,
          name: "Softbox",
          live: "https://soft-box.netlify.app",
          logo: "images/projects/softbox-logo.svg",
          image: "images/projects/softbox.jpg",
          description: `Softbox is a Software Development Company, 
                        SoftBox is at the forefront as the most innovative web developement company. this project has two sections a landing page and a mini shop.`,
          tools: ["vue", "vuetify"],
        },
        {
          id: 3,
          development: false,
          name: "Le Point Travaux",
          description: `The website for Point Travaux was created using Html, css and javascript. The services offered by the company, including electrical work, plumbing, plastering, tiling, and painting, were highlighted on individual pages with detailed descriptions, images, and all informations. The goal was to provide a clear and comprehensive representation of the company's offerings, making it easy for customers to understand and choose the services they need.`,
          live: "https://lepointtravaux.ma",
          logo: "images/projects/lepointtravaux-logo.png",
          image: "images/projects/lepointtravaux.jpg",
          tools: ["html", "css", "js "],
        },
      ],
    };
  },
  computed: {
    display() {
      return useDisplay();
    },
  },
};
</script>
<style scoped>
.point {
  font-size: 40px;
  font-weight: bold;
}
</style>