<template>
    <div id="contact" class="bg-white container py-6 pb-12 overflow-hidden bg-primary-color">
        <v-container :class="{'mx-width': display.lgAndUp.value}">
            <div class="tw-flex tw-flex-col">
                    <div class="tw-flex tw-flex-col">
                        <div class="tw-flex text-primary-green tw-items-center">
                            <div>04.</div>
                            <div class="tw-border-b-emerald-800 tw-border-b tw-w-5 mx-1"></div>
                            <div>What’s Next?</div>
                            <div class="tw-border-b-emerald-800 tw-border-b tw-w-7 ml-1"></div>
                        </div>
                        <div class="text-white text-md-h4 text-h5 tw-font-bold">
                            Say Hello
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="tw-grid tw-grid-cols-12">
                            <div class="md:tw-col-span-6 tw-col-span-full">
                                <div class="tw-h-full tw-flex tw-flex-col tw-justify-center">
                                    <div class="tw-font-light">
                                        <div class="tw-px-2 tw-rounded-sm tw-bg-emerald-500 tw-w-fit tw-text-sm mb-2 tw-font-bold text-primary-color">Information</div>
                                        Let's make something amazing together.
                                    </div>
                                    <div class="mt-10 md:tw-w-fit tw-w-full tw-flex tw-flex-col tw-gap-1">
                                        <v-hover v-slot="{isHovering, props}">
                                            <div v-bind="props" :class="[{'!tw-border-emerald-500/40 !tw-bg-emerald-500/20': isHovering}]"  class="text-primary-green  tw-bg-emerald-500/5 tw-duration-300 tw-rounded-lg tw-border tw-cursor-pointer tw-w-full md:tw-w-fit tw-border-transparent tw-px-8 tw-py-4 tw-mt-2">
                                                <a  href="mailto:yassineelaatmani47@gmail.com" class="tw-flex tw-items-center tw-gap-5">
                                                    <v-icon size="small">mdi-at</v-icon>
                                                    <p class="tw-text-sm tw-font-light tw-text-white">yassineelaatmani47@gmail.com</p>
                                                </a>
                                            </div>
                                        </v-hover>
                                        <v-hover v-slot="{isHovering, props}">
                                            <div v-bind="props" :class="[{'!tw-border-emerald-500/40 !tw-bg-emerald-500/20': isHovering}]"  class="text-primary-green tw-bg-emerald-500/5 tw-w-full tw-duration-300 tw-rounded-lg tw-border tw-cursor-pointer tw-border-transparent tw-px-8 tw-py-4 tw-mt-2 ">

                                                <a href="tel:+212608944024" class="tw-flex tw-items-center tw-gap-5">
                                                    <v-icon size="small">mdi-phone-outline</v-icon>
                                                    <p class="tw-text-sm tw-font-light tw-text-white">(+212) 608-944024</p>
                                                </a>
                                            </div>
                                        </v-hover>
                                    </div>
                                </div>
                            </div>
                            <div class="md:tw-col-span-6 tw-col-span-full">
                                <div class="tw-h-[350px] tw-relative">
                                    <img class="tw-absolute tw-left-0 md:-tw-top-36 tw-w-full" src="images/pose_45.png" alt="">
                                    <!-- <div class="tw-p-5 tw-bg-neutral-900 tw-border tw-border-emerald-500/20 tw-shadow-lg tw-shadow-emerald-400/5 tw-rounded-md">
                                        <p class="tw-text-sm tw-font-light">Contact Form</p>
                                        <div class="mt-6 tw-text-sm tw-font-light">
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <div>
                                                        <p class="mb-2">Your Name</p>
                                                        <v-text-field :hide-details="true" placeholder="Enter your name" variant="outlined" color="primary-green" density="compact"></v-text-field>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <div>
                                                        <p class="mb-2">Mail</p>
                                                        <v-text-field :hide-details="true" placeholder="Your e-mail" variant="outlined" color="primary-green" density="compact"></v-text-field>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <div>
                                                        <p class="mb-2">Message</p>
                                                        
                                                        <v-textarea :hide-details="true" placeholder="Your message" variant="outlined" color="primary-green" density="compact"></v-textarea>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="tw-flex tw-justify-end tw-mt-10">
                                            <v-btn variant="flat" color="primary-green" class="text-capitalize">Send Message</v-btn>

                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>
        </v-container>
    </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {

    data() {
        return {}
    },
    computed: {
        display() {
            return useDisplay()
        }
    }
    
}
</script>
<style scoped>
</style>