<template>
    <div class="bg-white container py-3 overflow-hidden bg-primary-color">
        <v-container :class="{'mx-width': display.lgAndUp.value}">
            <div class="text-center tw-text-neutral-200 tw-text-xs">
                Designed & Built by <span class="text-primary-green">Elaatmani Yassine</span>
            </div>
        </v-container>
    </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {

    data() {
        return {}
    },
    computed: {
        display() {
            return useDisplay()
        }
    }
    
}
</script>
<style scoped>
</style>