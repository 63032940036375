import { createRouter, createWebHistory } from 'vue-router'

// Routes
import homeRoutes from './routes/home.routes'
import dashboardRoutes from './routes/dashboard.routes'

// Handlers
import beforeEachHandler from './handlers/beforeEachHandler'


const routes = [
  homeRoutes,
  dashboardRoutes,

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    beforeEnter: () => {
      return { name: 'home' }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(beforeEachHandler)

export default router
