import DefaultLayout from '@/views/layouts/DefaultLayout.vue'
import HomeView from '@/views/pages/HomeView.vue'


export default {
    path: '/',
    name: 'home-layout',
    component: DefaultLayout,
    meta: {
      title: 'Welcome to my portfolio',
      layout: 'default'
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      }
    ],
  }