<template>
    <div class="y-header" :style="headerStyle">
        <v-container class="d-flex justify-space-between align-center h-100">
            <img class="tw-w-[130px]" src="/images/logo.png" alt="elaatmani logo">
            <!-- <h1 id="logo"><span class="logo font-weight-bold tw-text-3xl" :class="logoColor">Aatmani</span><span class="text-h3 d-inline-block logo-dot text-primary-green">.</span></h1> -->
            <div class="y-menu">
                <NavbarMenu @closeMenu="handleCloseMenu" />
            </div>
        </v-container>
    </div>
</template>

<script>
import { useDisplay } from 'vuetify/lib/framework.mjs';
import NavbarMenu from './NavbarMenuVue.vue';
export default {
    components: { NavbarMenu },

    data() {
        return {
            isActive: false,
            yOffset: 0
        };
    },
    computed: {
        display() {
            return useDisplay();
        },
        logoColor(){
            const color = 'text-primary-dark-text'

            // const color = this.display.mdAndUp.value ?
            // 'text-primary-dark-text' : this.isActive ?
            // 'text-grey-darken-3' : 'text-primary-dark-text';
            return color
        },
        headerStyle() {
            return {
                height: this.display.lgAndUp.value ? '150px' : '100px'
            };
        },
        isShowBackground() {
            return this.yOffset > 50 
        }
    },
    methods: {
        handleCloseMenu(v) {
            this.isActive = v
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            this.yOffset = window.pageYOffset
        })
    }
}
</script>

<style scoped>
    .y-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* background: #000; */
        width: 100%;
        z-index: 100;
        /* backdrop-filter: blur(5px); */
        transition: height .2s ease-out;
    }



    #logo {
        font-family: 'Comfortaa', cursive !important;
        font-weight: bold !important;
        position: relative;
        z-index: 101;
    }

    .logo {
        font-family: 'Comfortaa', cursive !important;
        user-select: none;
    }

    .logo-dot {
        transform: translateY(-38%);
        margin-left: -11.5px;
    }
</style>