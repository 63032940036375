import DashboardLayout from '@/views/layouts/DashboardLayout.vue'
import AboutView from '@/views/pages/AboutView.vue'

export default {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardLayout,
    children: [
      {
        path: 'test',
        name: 'test',
        component: AboutView
      }
    ]
  }