<template>
  <div class="home">
    <HeroSection />

    <AboutMeSection />

    <SkillsSection />

    <ProjectsSection />

    <ContactForm />

    <Footer />
  </div>
</template>

<script>
// Components
import HeroSection from '@/components/home/HeroSection'
import AboutMeSection from '@/components/home/AboutMeSection'
import SkillsSection from '@/components/home/SkillsSection'
import ProjectsSection from '@/components/home/ProjectsSection'
import ContactForm from '@/components/home/ContactForm'
import Footer from '@/components/app/FooterVue'

export default {
  name: 'HomeView',

  components: { HeroSection, SkillsSection, ProjectsSection, AboutMeSection, ContactForm, Footer },
};
</script>

<style scoped>
</style>