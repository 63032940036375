<template>
    <div class="bg-primary-color  container pt-16 pt-md-6 overflow-hidden" id="hero">
        <v-container class="mx-height" :class="{'mx-width': display.lgAndUp.value}">
            <div class="pt-8 pt-md-10 pb-10">
                <v-row class="pa-0 pt-16">
                    <v-col cols="12" md="6" >
                        <div class="h-100 d-flex align-start text-grey-lighten-4 flex-column justify-center pr-16">
                            <div class="text-md-h2 text-h3 font-weight-black" :style="{'line-height': heroTextHeight}">
                                <span>Full Stack </span> 
                                <div class="d-flex align-end mt-md-n2 mt-n5">
                                    <div class="stack" style="--stacks: 3;">
                                        <span style="--index: 0;">Developer</span>
                                        <span style="--index: 1;">Developer</span>
                                        <span style="--index: 2;">Developer</span>
                                        <span>Developer</span>
                                    </div>
                                    <span class="d-block text-h1 ml-n2 text-primary-green" :style="{'line-height': '70px'}">.</span>
                                </div>
                                
                            </div>
                            <div class="mt-10 text-primary-dark-text font-weight-light">
                                <!-- <span class="d-block mb-1 text-subtitle-1">Hi, Im <span class="text-primary-green">&lt;</span> El Aatmani Yassine<span class="text-primary-green"> /&gt;</span></span>  -->
                                I'm a web developer based in Marrakech 🇲🇦.
                                Love working on Web technologies and looking for an opportunity to refine my knowledge.
                            </div>
                            <div class="mt-10">
                                <v-hover v-slot="{isHovering, props}">
                                    <v-btn href="#contact" v-bind="props"  :variant="isHovering ? 'outlined' : 'flat'" size="large" color="primary-green" rounded="lg" class="text-capitalize cta-btn">Say hi !<v-icon :end="true">mdi-emoticon-excited-outline</v-icon></v-btn>
                                </v-hover>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="h-100 mt-6 mt-md-5 d-flex justify-center position-relative ">
                            <img draggable="false" src="images/pose_47.png" class="pose-img" alt="">
                            <div class="coding-screen pa-3">
                                <div class="coding-line coding-screen-line-1"></div>
                                <div class="coding-line coding-screen-line-2"></div>
                                <div class="coding-line coding-screen-line-3"></div>
                                <div class="coding-line coding-screen-line-4"></div>
                                <div class="coding-line coding-screen-line-5"></div>
                                <div class="coding-line coding-screen-line-6"></div>
                                <div class="coding-line coding-screen-line-7"></div>
                            </div>
                            <div class="svg-container ml-md-8">
                                <img draggable="false" class="tool-svg svg-1" src="images/svg/laravel.svg" >
                                <img draggable="false" class="tool-svg svg-2" src="images/svg/php.svg" >
                                <img draggable="false" class="tool-svg svg-3" src="images/svg/react.svg" >
                                <img draggable="false" class="tool-svg svg-4" src="images/svg/js.svg" >
                                <img draggable="false" class="tool-svg svg-5" src="images/svg/vue.svg" >
                                <img draggable="false" class="tool-svg svg-6" src="images/svg/vuetifyjs.svg" >
                                <img draggable="false" class="tool-svg svg-7" src="images/svg/github.svg" >
                                <img draggable="false" class="tool-svg svg-8" src="images/svg/html-5.svg" >
                                <img draggable="false" class="tool-svg svg-9" src="images/svg/css3.svg" >
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <div class="container mx-auto position-relative mb-15 mt-n10 py-md-5 py-10">
            <div class="scroll-container"></div>
        </div>
    </div>
</template>
<script>
    import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {
    computed: {
        display() {
            return useDisplay();
        },
        heroTextHeight() {
            return this.display.mdAndUp.value ? '45px' : '33px'
        }
    }
    
}
</script>
<style scoped>

    .svg-container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        /* background: red; */
        
    }

    .tool-svg {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 0;
        padding: 5px;
        border-radius: 10px;
        background: rgba(255, 255, 255, .2);
        border: 1px solid rgba(255, 255, 255, .5);
        top: 30%;
        left: 30%;
    }

    
    
    .svg-1 {
        animation: float-1 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
    }
    
    .svg-2 {
        animation: float-2 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 2.2s;
    }
    
    .svg-3 {
        animation: float-3 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 2.4s;
    }
    
    .svg-4 {
        animation: float-4 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 2.6s;
    }

    .svg-5 {
        animation: float-5 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 2.8s;
    }

    .svg-6 {
        animation: float-6 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 3s;
    }

    .svg-7 {
        animation: float-7 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 3.2s;
    }

    .svg-8 {
        animation: float-8 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 3.4s;
    }

    .svg-9 {
        animation: float-9 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 3.6s;
    }

    @media screen and (max-width: 968px) {
        .svg-container {
            /* transform: scale(.9); */
            max-width: 420px;
        }


        .svg-1 {
            animation: float-1 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        .svg-2 {
            animation: float-2 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
        }

        .svg-3 {
            animation: float-3 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) .6s;
        }
        
        .svg-4 {
            animation: float-4 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) .9s;
        }

        .svg-5 {
            animation: float-5 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
        }

        .svg-6 {
            animation: float-6 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
        }

        .svg-7 {
            animation: float-7 4.5s alternate cubic-bezier(0.075, 0.82, 0.165, 1) 1.8s;
        }

        .svg-8 {
            animation: float-8 8s forwards cubic-bezier(1, .1, 0, .9) .4s;
        }

        .svg-9 {
            animation: float-9 4.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 1.6s;
        } 
            /*         
        */


        .svg-1, 
        .svg-2, 
        .svg-3, 
        .svg-4, 
        .svg-5, 
        .svg-6, 
        .svg-7, 
        .svg-8, 
        .svg-9 {
            animation-fill-mode: backwards;
            animation-iteration-count: infinite;
        } 

        /* .svg-1,  */
        /* .svg-2,  */
        /* .svg-3,  */
        /* .svg-4,  */
        /* .svg-5,  */
        /* .svg-6,  */
        /* .svg-7,  */
        .svg-8, 
        .svg-9 {
            visibility: hidden;
        } 
    }

    .coding-screen {
        width: 70%;
        min-width: 300px;
        max-width: 360px;
        height: 160px;
        background: rgb(68, 68, 68);
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        border-radius: 10px;
        filter: drop-shadow(10px 10px 20px rgba(21, 21, 21, 0.4));
        border: 2px solid rgba(255, 255, 255, 0.1);
    }

    .coding-line {
        position: relative;
        height: 10px;
        width: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .coding-screen-line-1::after, 
    .coding-screen-line-2::after, 
    .coding-screen-line-3::after,
    .coding-screen-line-4::after,
    .coding-screen-line-5::after,
    .coding-screen-line-6::after, 
    .coding-screen-line-7::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 10px;
        border-radius: 10px;
    }

    .coding-screen-line-1::before, 
    .coding-screen-line-2::before, 
    .coding-screen-line-3::before,
    .coding-screen-line-4::before,
    .coding-screen-line-5::before,
    .coding-screen-line-6::before, 
    .coding-screen-line-7::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 10px;
        border-radius: 10px;
    }

    .coding-screen-line-1::before {
        background-color: #f7ba3e;
        width: 60%;
    }

    .coding-screen-line-1::after {
        background-color: #56b3b4;
        width: 38%;
    }

    .coding-screen-line-2::before {
        background-color: #ea5e5e;
        width: 10%;
    }

    .coding-screen-line-2::after {
        background-color: #bf85bf;
        width: 88%;
    }

    .coding-screen-line-3::before {
        background-color: #56b3b4;
        width: 70%;
    }
    
    .coding-screen-line-3::after {
        background-color: #ea5e5e;
        width: 28%;
    }


    .coding-screen-line-4::before {
        background-color: #bf85bf;
        width: 60%;
    }
    
    .coding-screen-line-4::after {
        background-color: #f7ba3e;
        width: 38%;
    }
    .coding-screen-line-5::before {
        background-color: #56b3b4;
        width: 70%;
    }
    
    .coding-screen-line-5::after {
        background-color: #ea5e5e;
        width: 28%;
    }

    .coding-screen-line-6::before {
        background-color: #f7ba3e;
        width: 30%;
    }
    
    .coding-screen-line-6::after {
        background-color: #ea5e5e;
        width: 68%;
    }

    .coding-screen-line-7::before {
        background-color: #bf85bf;
        width: 60%;
    }
    
    .coding-screen-line-7::after {
        background-color: #f7ba3e;
        width: 38%;
    }

    .hero-text, .hero-text span {
        /* font-family: 'Reem Kufi Fun', sans-serif !important; */
        line-height: 45px !important;
    }


  .pose-img {
    min-width: 420px;
    transform: translateX(10%);
    width: 100%;
    max-width: 500px;
    z-index: 6;
  }

  .container {
    min-height: fit-content !important;
    /* height: 100vh; */
    max-height: 1150px !important;
  }

  .cta-btn {
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, .15));
    border: 1px solid rgb(var(--v-theme-primary-green));
    /* border: 2px solid rgba(0, 0, 0, .25); */
  }

  .scroll-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .scroll-container::after {
    content: '';
    height: 45px;
    width: 30px;
    border: 2px solid rgb(var(--v-theme-primary-green));
    border-radius: 10px;
    position: absolute;
    top: -0%;
    left: 50%;
    transform: translateX(-50%);
  }

  .scroll-container::before {
    content: '';
    height: 15px;
    width: 5px;
    background: rgb(var(--v-theme-primary-green));
    border-radius: 5555px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    animation: scroll-down 3s forwards infinite ease  ;
  }

  .show-item {
    animation: show-element .5s forwards 4s;
  }

  @keyframes scroll-down {
    0%, 10% {
        height: 5px;
        transform: translate(-50%, 0%);
    }

    40% {
        height: 15px;
        transform: translate(-50%, 0);
    }

    60% { 
        transform: translate(-50%, 15px);
        opacity: 1;
        height: 5px;
    }



    100% {
        transform: translate(-50%, 15px);
        height: 5px;
        opacity: 0;
    }
  }

  @keyframes show-element {
    from {
        opacity: 0;
        
    }
    to {
        opacity: 1;
    }
  }

  .stack {
  display: grid;
  grid-template-columns: 1fr;
  height: fit-content;
}

.stack span {
    height: fit-content;
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  /* font-size: 4rem; */
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(calc(var(--index) * 120ms) + 2600ms), glitch 2s ease infinite 5s alternate-reverse;
}

.stack span:nth-child(odd) { --glitch-translate: 12px; }
.stack span:nth-child(even) { --glitch-translate: -12px; }

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 #ea5e5e, 2px -3px 0 rgb(var(--v-theme-primary-green));
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 #ea5e5e, -2px 3px 0 rgb(var(--v-theme-primary-green));
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 #ea5e5e, 2px -3px 0 rgb(var(--v-theme-primary-green));
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 #ea5e5e, -2px 3px 0 rgb(var(--v-theme-primary-green));
  }
  4%, 100% {  text-shadow: none; transform: none; }
}
</style>