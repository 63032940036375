<template>
  <div class="loading-container">
    <div class="circle-container">
        <div class="circle">

        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .loading-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgb(var(--v-theme-primary-color));
        animation: spread 3s forwards 2s;
        z-index: 102;
    }

    .circle-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        
    }


    .circle {
        position: relative;
        width: 30px;
        height: 30px;
        background: rgb(var(--v-theme-primary-green));
        border-radius: 50%;
        animation: hide 3s forwards 2s;
    }

    .circle::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background: rgb(var(--v-theme-primary-green));
        border-radius: 50%;
        animation: zoom-in-out 2s backwards 1s infinite;
    }

    .circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background: rgb(var(--v-theme-primary-green));
        border-radius: 50%;
        animation: zoom-in-out 2s backwards infinite;
    }

    @keyframes hide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes zoom-in-out {
        0% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }

        100% {
            /* width: 120%; */
            /* height: 120%; */
            transform: translate(-50%, -50%) scale(2);
            opacity: 0;
        }
    }

    @keyframes spread {
        0% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            /* width: 120%; */
            /* height: 120%; */
            transform: scale(50);
            opacity: 0;
        }
    }
</style>