<template>
    <div class="y-menu" :class="display.mdAndUp.value ? 'y-md-and-up' : 'y-sm-and-down'" v-click-outside="closeMenu">
        <transition :name="display.mdAndUp.value ? 'menu' : 'menu-mobile'"  @leave="isActiveLink = false" @enter="isActiveLink = true">
            <div class="y-menu-content pa-5 pa-md-10 pb-5 d-flex flex-column bg-white justify-space-between" v-if="isActive">
                <transition :name="display.mdAndUp.value ? 'menu-link' : 'menu-link'">
                    <div v-if="isActiveLink" class="h-75 justify-space-around d-flex flex-column pt-5 pt-md-0">

                        <v-btn link href="#about" :width="display.mdAndUp.value ? 'fit-content' : 'fit-content'" :ripple="false" color="grey-darken-4" class="mb-6 mb-md-0 y-hover-text no-hover text-body-1 text-md-subtitle-1 text-capitalize font-weight-medium"  variant="text"><span>About me</span></v-btn>
                        <v-btn link href="#skills" :width="display.mdAndUp.value ? 'fit-content' : 'fit-content'" :ripple="false" color="grey-darken-4" class="mb-6 mb-md-0 y-hover-text no-hover text-body-1 text-md-subtitle-1 text-capitalize font-weight-medium"  variant="text"><span>Skills</span></v-btn>
                        <v-btn link href="#projects" :width="display.mdAndUp.value ? 'fit-content' : 'fit-content'" :ripple="false" color="grey-darken-4" class="mb-6 mb-md-0 y-hover-text no-hover text-body-1 text-md-subtitle-1 text-capitalize font-weight-medium"  variant="text"><span>My Work</span></v-btn>
                        <v-btn link href="#contact" :width="display.mdAndUp.value ? 'fit-content' : 'fit-content'" :ripple="false" color="grey-darken-4" class="mb-6 mb-md-0 y-hover-text no-hover text-body-1 text-md-subtitle-1 text-capitalize font-weight-medium"  variant="text"><span>Contact Me</span></v-btn>
                    </div>
                    
                </transition>
                <transition :name="display.mdAndUp.value ? 'menu-link-1' : 'menu-link-1'" >
                    <div v-if="isActiveLink" class="w-100 d-flex justify-space-around mt-5 mb-5">
                        <!-- <v-btn color="blue-lighten-2" variant="text" icon><v-icon :size="display.mdAndUp.value ? 'large' : 'large'">mdi-twitter</v-icon></v-btn> -->
                        <a target="_blank" href="https://www.linkedin.com/in/yassine-e-38a68a11a/" color="blue-darken-2" variant="text" icon><v-icon color="blue-darken-2" :size="display.mdAndUp.value ? 'x-large' : 'x-large'">mdi-linkedin</v-icon></a>
                        <a target="_blank" href="https://github.com/elaatmani" color="black" variant="text" icon><v-icon :size="display.mdAndUp.value ? 'x-large' : 'x-large'">mdi-github</v-icon></a>
                    </div>
                </transition>
            </div>
        </transition>
        <div class="y-menu-toggler">
            <v-btn class="no-hover" size="x-large" :ripple="false" icon :color="isActive && display.mdAndUp.value ? 'primary-color' : 'white'" @click="isActive = !isActive" variant="text"><v-icon>mdi-{{isActive ? 'close' : 'menu'}}</v-icon></v-btn>
        </div>  
        
    </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs';
export default {
    data() {
        return {
            isActive: false,
            isActiveLink: false

        }
    },
    methods:{
        closeMenu() {
            this.isActiveLink = false
            this.isActive = false
        },
    },
    watch: {
        isActive(v) {
            this.$emit('closeMenu', v)

        }
    },
    computed: {
        display() {
            return useDisplay()
        }
    }
}
</script>
<style  scoped>
    .y-menu {
        position: relative;
        width: fit-content;
        z-index: 101;
    }

    .y-md-and-up .y-menu-content {
            position: absolute;
            width: 300px;
            height: 400px;
            background: white;
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, .15));
            right: 0;
            border: 2px solid rgba(0, 0, 0, .25);
            border-radius: 10px;
        
    }   

    /* Option 1 full screen */
    /* .y-sm-and-down .y-menu-content {
            position: fixed;
            min-width: 300px;
            min-height: 600px;
            width: 100vw;
            height: 100vh;
            background: rgb(var(--v-blue));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, .05));
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            padding-bottom: 120px !important;
    } */

    /* option 2 */
    .y-sm-and-down .y-menu-content {
            position: absolute;
            width: 270px;
            height: 370px;
            background: white;
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, .15));
            right: 0px;
            top: 60px;
            border: 2px solid rgba(0, 0, 0, .25);
            border-radius: 10px;
    }

    .y-sm-and-down .y-menu-content::after{
        content: "";
        position: absolute;
        top: -10px;
        right: 20px;
        width: 20px;
        height: 20px;
        background: white;
        border: 2px solid rgba(0, 0, 0, .25);
        border-top-color: transparent;
        border-right-color: transparent;
        transform: rotate(135deg);
    }

    .opacity-0 {
        opacity: 0;
    }
</style>