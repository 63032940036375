<template>
  <div>
    <div v-if="showLoading">
      <LoadingVue />
    </div>
    <div>
      <NavbarVue />
      <v-main>
          <router-view/>
      </v-main>
    </div>
  </div>
</template>

<script>
import NavbarVue from '@/components/app/NavbarVue'
import LoadingVue from '@/components/app/LoadingVue'


export default {
    components: { NavbarVue, LoadingVue },
    data() {
      return {
        showLoading: true
      }
    },
    mounted() {
      setTimeout(() => {
        this.showLoading = false
      }, 4500)
    }
}
</script>

<style>

</style>