<template>
  <div id="skills" class="bg-primary-color container py-md-10 pt-10 pb-0 tw-relative">
    <v-container
      :class="{ 'mx-width': $vuetify.display.lgAndUp }"
      class="tw-overflow-hidden md:tw-overflow-visible py-md-16 tw-relative tw-z-10 "
    >
      <div>
            <div class="">
                <div class="tw-flex text-primary-green tw-items-center">
                    <div>02.</div>
                    <div class="tw-border-b-emerald-800 tw-border-b tw-w-5 mx-1"></div>
                    <div>Skills</div>
                    <div class="tw-border-b-emerald-800 tw-border-b tw-w-7 ml-1"></div>
                </div>
                <div class="text-white text-md-h4 text-h5 tw-font-bold">
                    What Can I Do
                    <!-- <span class="point text-pink">.</span> -->
                </div>
            </div>
      </div>
      <div class="mt-10 mt-md-16">
        <div
          class="
            text-primary-dark-text text-md-body-1 text-body-1
            font-normal
            mb-8
            py-5
          "
        >
          <v-row>
            <v-col cols="12" md="6" :order="mdAndUp ? 1 : 2">
              <div class="tw-flex tw-mt-5 tw-h-full tw-flex-col tw-justify-center">
                  <span class="tw-block mb-2 tw-text-sm tw-w-fit tw-bg-emerald-500/10 tw-text-emerald-500 tw-px-2 tw-rounded-md">As a Full Stack developer</span>
                <p class="md:tw-text-base text-body-2 tw-text-sm tw-font-light">
                  I have a superpower, and it's not
                  X-ray vision or the ability to fly (although that would be
                  pretty cool). My superpower is coding. I can take a jumbled
                  mess of 1s and 0s and turn it into a beautiful website that's
                  easy to use and even easier on the eyes. 
                  If you're still skeptical, just check out my skills listing. It's pretty
                  impressive.
                </p>
                <p class="mt-7 text-body-2 tw-font-light">Languages I Speak:</p>
                <div class="mt-2 tw-flex tw-gap-3 tw-flex-wrap">
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-sky-400 tw-bg-sky-400/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/react.svg" alt="react">
                        <span class="text-body-2">React</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-emerald-400 tw-bg-emerald-400/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/vue.svg" alt="vue">
                        <span class="text-body-2">Vue</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-red-400 tw-bg-red-400/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/laravel.svg" alt="laravel">
                        <span class="text-body-2">Laravel</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-yellow-500 tw-bg-yellow-500/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/js.svg" alt="javascript">
                        <span class="text-body-2">JavaScript</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-orange-400 tw-bg-orange-400/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/html-5.svg" alt="html">
                        <span class="text-body-2">HTML</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-blue-500 tw-bg-blue-500/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/css3.svg" alt="css">
                        <span class="text-body-2">CSS</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-cyan-500 tw-bg-cyan-500/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/tailwind.svg" alt="tailwind">
                        <span class="text-body-2">Tailwind</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-blue-300 tw-bg-blue-300/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/php.svg" alt="php">
                        <span class="text-body-2">Php</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-slate-400 tw-bg-slate-400/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/mysql.svg" alt="mysql">
                        <span class="text-body-2">MySql</span>
                    </div>
                    <div class="px-3 tw-py-1 tw-rounded-md tw-w-fit tw-flex tw-text-sky-500 tw-bg-sky-500/10 tw-items-center tw-gap-2">
                        <img width="25" src="images/svg/vuetifyjs.svg" alt="vuetify">
                        <span class="text-body-2">Vuetify</span>
                    </div>
                </div>
                
              </div>
            </v-col>
            <v-col cols="12" md="6" class="md:tw-mb-10" :order="mdAndUp ? 2 : 1">
              <!-- <div class="tw-max-w-[500px] tw-h-[300px] tw-mb-5 md:tw-mb-16">
                <img class="w-100 tw-object-cover md:-tw-translate-y-20 -tw-translate-y-16 tw-translate-x-32 sm:tw-translate-x-20 md:tw-translate-x-32" src="images/pose_52.png" alt="" />
              </div> -->
              <div class="tw-relative tw-h-[250px]">
                <div class="tw-h-full tw-hidden tw-max-w-[500px] md:tw-block tw-relative tw-shadow-lg navigator-1 tw-p-5 tw-bg-emerald-400/20 tw-w-[80%] tw-mr-3 tw-ml-auto tw-rounded-lg">
                    <div class="tw-w-1/3 tw-py-10 tw-bg-emerald-400/10 tw-rounded-md">
                    </div>
                </div>
                <div class="tw-min-h-[250px] tw-max-w-[500px] tw-absolute tw-backdrop-blur-lg tw-border tw-border-emerald-500/20 md:tw-top-10 tw-top-0 tw-right-0 tw-shadow-lg navigator-1 tw-p-5 tw-bg-gradient-to-l tw-from-emerald-900/10 tw-to-emerald-800/50 md:tw-w-2/3 tw-w-full tw-rounded-lg tw-ml-auto">
                    <div class="tw-flex tw-gap-3">
                        <div class="tw-w-[10px] tw-h-[10px] tw-rounded-full tw-bg-red-500"></div>
                        <div class="tw-w-[10px] tw-h-[10px] tw-rounded-full tw-bg-yellow-500"></div>
                        <div class="tw-w-[10px] tw-h-[10px] tw-rounded-full tw-bg-emerald-500"></div>
                    </div>
                    <div class="mt-8 tw-flex tw-flex-wrap tw-gap-5">
                        <div class="py-1 tw-w-1/3 tw-rounded-lg tw-bg-gradient-to-l tw-from-blue-400 tw-to-emerald-400 tw-animate-pulse"></div>
                        <div class="py-1 tw-w-1/3 tw-rounded-lg tw-bg-gradient-to-l  tw-from-emerald-400 tw-to-blue-400 tw-delay-300 tw-animate-pulse"></div>
                        <div class="py-1 tw-w-2/3 tw-rounded-lg tw-bg-gradient-to-l tw-from-teal-400/50 tw-to-indigo-400 tw-delay-500 tw-animate-pulse"></div>
                        <div class="py-1 tw-w-3/6 tw-rounded-lg tw-bg-gradient-to-l tw-from-sky-400/50 tw-to-green-400 tw-delay-500 tw-animate-pulse"></div>
                        <div class="py-1 tw-w-1/5 tw-rounded-lg tw-bg-gradient-to-l tw-from-cyan-400/50 tw-to-indigo-400 tw-delay-500 tw-animate-pulse"></div>
                    </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mt-16 pb-md-16 pb-10 pb-md-0">
          <!-- <v-row class="pt-10 pt-md-0">
            <v-col v-for="skill in skills" :key="skill.id" cols="6" sm="4" md="2">
                <div>
                    <SkillCard :lang="skill.lang" :icon="skill.icon" :name="skill.name" />
                </div>
            </v-col>
          </v-row> -->
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
// import SkillCard from "@/components/home/partials/SkillCard";

export default {
  components: { },
//   components: { SkillCard },

  data() {
    return {
        skills: [
            {
                id: 1,
                name: 'HTML',
                icon: 'images/svg/html-5.svg',
                lang: 'html'
            },
            {
                id: 2,
                name: 'CSS',
                icon: 'images/svg/css3.svg',
                lang: 'css'
            },
            {
                id: 3,
                name: 'JavaScript',
                icon: 'images/svg/js.svg',
                lang: 'js'
            },
            {
                id: 4,
                name: 'PHP',
                icon: 'images/svg/php.svg',
                lang: 'php'
            },
            {
                id: 5,
                name: 'MySql',
                icon: 'images/svg/mysql.svg',
                lang: 'mysql'
            },
            {
                id: 6,
                name: 'Tailwind',
                icon: 'images/svg/tailwind.svg',
                lang: 'tailwind'
            },
            {
                id: 7,
                name: 'ReactJs',
                icon: 'images/svg/react.svg',
                lang: 'vue'
            },
            {
                id: 8,
                name: 'VueJs',
                icon: 'images/svg/vue.svg',
                lang: 'vue'
            },
            {
                id: 9,
                name: 'Vuetify',
                icon: 'images/svg/vuetifyjs.svg',
                lang: 'vuetify'
            },
            {
                id: 10,
                name: 'Laravel',
                icon: 'images/svg/laravel.svg',
                lang: 'laravel'
            }
        ]
    };
  },
  computed: {
    mdAndUp() {
      return this.$vuetify.display.mdAndUp;
    },
  },
};
</script>
<style scoped>
.point {
  font-size: 40px;
  font-weight: bold;
}

.font-normal {
  font-weight: normal !important;
}

</style>