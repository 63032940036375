<template>
  <div class="about">
    <h1>This is an about page</h1>
    <div class="icn">
      <Icon icon="codicon:search" />
      <Icon icon="fa:internet-explorer" />
      
    </div>
    <router-link to="/dashboard/">dashb</router-link>

  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
export default {
  components: { Icon }
}
</script>

<style scoped>


.icn {
  font-size: 80px;
  color: gray;
}

</style>