<template>
  <div class="tw-relative my-10" v-if="project.id !== 0">
    <v-row no-gutters>
      <v-col v-if="$vuetify.display.mdAndUp" cols="12" md="7" :order="isRight ? 1 : 2">
        <div class="tw-h-[400px] tw-relative tw-rounded-lg tw-overflow-hidden">
          <a :href="project.live" target="_blank" class="tw-absolute tw-w-full tw-h-full tw-z-10 hover:tw-opacity-0 tw-duration-300 tw-bg-emerald-800/50"></a>
          <img class="tw-shadow-lg tw-w-full tw-h-full tw-object-cover" :src="project.image" alt="">
        </div>
      </v-col>
      <v-col cols="12" md="5" :order="isRight ? 2 : 1">
        <div class="tw-absolute md:tw-hidden tw-top-0 tw-left-0 tw-h-full  tw-w-full tw-overflow-hidden tw-rounded-lg">
          <img :src="project.image" class="tw-absolute tw-object-cover  tw-top-0 tw-left-0 tw-rounded-xl tw-h-full tw-w-full" />
          <div class="tw-absolute tw-object-cover tw-top-0 tw-left-0  tw-rounded-md tw-h-full tw-z-10 tw-w-full tw-bg-neutral-900/90 tw-backdrop-blur-sm"></div>
        </div>
        <div :class="{'md:!tw-text-end': isRight}" class="text-start tw-shadow-lg md:tw-shadow-none md:tw-border-none tw-border tw-border-emerald-400/10 md:tw-rounded-none tw-rounded-xl tw-py-6 tw-px-5 md:tw-px-0 tw-relative tw-flex tw-flex-col tw-z-20">
          <p class=" mb-2 text-primary-green tw-text-sm">Featured Project</p>
          <p class="tw-text-xl tw-font-bold tw-text-neutral-200 mb-5 t">{{ project.name }}</p>
          <div :class="{'-tw-translate-x-[16%]': isRight && $vuetify.display.mdAndUp, 'tw-translate-x-[0%]': !isRight && $vuetify.display.mdAndUp, '!tw-w-[120%]': $vuetify.display.mdAndUp}" class="tw-bg-neutral-800 tw-w-full tw-text-neutral-300 tw-shadow-lg tw-py-7 pa-5 px-7 md:tw-text-sm tw-text-xs tw-rounded-md ">
            <p v-html="project.description">
              
            </p>
          </div>
          <div :class="{'tw-justify-end': isRight && $vuetify.display.mdAndUp}" class="mt-5 tw-flex tw-gap-2 tw-flex-wrap">
            <div v-if="project.tools.includes('vue')" class="tw-text-sm px-2 tw-bg-emerald-500/10 tw-rounded-md tw-text-emerald-500">Vue</div>
            <div v-if="project.tools.includes('vuetify')" class="tw-text-sm px-2 tw-bg-blue-500/10 tw-rounded-md tw-text-blue-500">Vuetify</div>
            <div v-if="project.tools.includes('php')" class="tw-text-sm px-2 tw-bg-indigo-300/10 tw-rounded-md tw-text-indigo-300">Php</div>
            <div v-if="project.tools.includes('mysql')" class="tw-text-sm px-2 tw-bg-neutral-300/10 tw-rounded-md tw-text-neutral-300">MySql</div>
            <div v-if="project.tools.includes('html')" class="tw-text-sm px-2 tw-bg-orange-500/10 tw-rounded-md tw-text-orange-500">Html</div>
            <div v-if="project.tools.includes('laravel')" class="tw-text-sm px-2 tw-bg-red-500/10 tw-rounded-md tw-text-red-500">Laravel</div>
            <div v-if="project.tools.includes('tailwind')" class="tw-text-sm px-2 tw-bg-cyan-500/10 tw-rounded-md tw-text-cyan-500">TailwindCSS</div>
            <div v-if="project.tools.includes('js')" class="tw-text-sm px-2 tw-bg-yellow-400/10 tw-rounded-md tw-text-yellow-400">Javascript</div>
            <div v-if="project.tools.includes('css')" class="tw-text-sm px-2 tw-bg-blue-400/10 tw-rounded-md tw-text-blue-400">Css</div>
            <div v-if="project.development" class="tw-text-sm px-2 tw-bg-orange-300/10 tw-rounded-md tw-text-orange-300"><v-icon size="small">mdi-source-branch</v-icon> in development</div>
          </div>
          <div :class="{'tw-justify-end': isRight && $vuetify.display.mdAndUp}" class="mt-5 tw-flex tw-gap-2">
            <v-hover v-slot="{isHovering, props}">
              <v-btn v-if="project.live"  :href="project.live" target="_blank" v-bind="props" :color="isHovering ? 'primary-green' : 'white'"  link icon class="pa-0 no-hover" density="comfortable" variant="text">
                <v-icon class="tw-duration-500" size="large">mdi-open-in-new</v-icon>
              </v-btn>
            </v-hover>
            <v-hover v-slot="{isHovering, props}">
              <v-btn v-if="project.github" :href="project.github" target="_blank" v-bind="props" :color="isHovering ? 'primary-green' : 'white'"  link icon class="pa-0 no-hover" density="comfortable" variant="text">
                <v-icon class="tw-duration-500" size="large">mdi-github</v-icon>
              </v-btn>
            </v-hover>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: ['project'],
    computed: {
      isRight() {
        return this.project.id % 2 == 1
      }
    }
}
</script>

<style>

</style>