import { createStore } from 'vuex'

export default createStore({
  state: {
    layout: 'default',
    delay: 0
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
