// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const darkTheme = {
  colors: {

    // Primary colors

    // 'primary-color': '#6E07F3', // purple
    'primary-color': '#1a1a1a',  // gray 
    // 'primary-color': '#0f172a', // blue gray

    'dark-blue': '#16213E',
    'dark-blue-light-1': '#0F3460',
    'primary-dark-text': '#EEEEEE',
    'dark-purple': '#533483',
    'dark-pink': '#E94560',

    'primary-purple-lighten-1': '87A2FB',
    'primary-green': '#5be9b9',
    'primary-green-lighten-1': '#ADDDD0'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'darkTheme',
    themes: {
      darkTheme
    }
  },
})
